import React from 'react';

export default {
  "header.viewAccount": "Ver mi cuenta",
  "header.description": "Comprendemos que la vida tiene sus contratiempos y trabajaremos con usted para ayudarle a resolver su deuda.",
  "header.title": "El camino para estar libre de deudas comienza con un solo paso",
  "header.altImage": "Mujer rodeada de gráficos",
  "splitSection.flexiblePlanTitle": "Planes flexibles para situaciones reales",
  "splitSection.flexiblePlanIllus": "Mujer sentada en un banco con el viento soplando en el fondo",
  "splitSection.flexiblePlanDesc": "Ya sea que necesite <b>flexibilidad a corto plazo</b> o un plan de pago a largo plazo, podemos ayudarle a encontrar una solución que le brinde <b>tranquilidad</b>",
  "splitSection.honestyTitle": "Honestidad y transparencia",
  "splitSection.honestyIllus": "Hombre y mujer dándose la mano",
  "splitSection.honestyDesc": "Le ayudaremos a <b>comprender sus opciones</b> y le proporcionaremos acceso a la información y documentación de su cuenta. Sin sorpresas.",
  "whoWeAre.title": "Quiénes somos",
  "whoWeAre.imageAlt": "Personas que hablan y ven una computadora",
  "whoWeAre.desc": "En pocas palabras, <b>operamos de manera diferente.</b> Pregunte a nuestros empleados, clientes o a los miles de consumidores a los que hemos ayudado en situaciones inesperadas. <b>Nos enorgullecemos</b> de ser solucionadores de problemas centrados en el consumidor. Confíe en nuestra experiencia y conocimientos.",
  "whoWeAre.button": "Más información sobre nosotros",
  "home.disclaimer": "Esta es una comunicación de un cobrador de deudas. Este es un intento de cobrar una deuda y cualquier información obtenida se utilizará para ese propósito.",
  "home.SCDisclaimerTitle": "<b>Aviso para residentes de Carolina del Sur</b></br>",
  "home.SCDisclaimer": "<a href=\"South_Carolina_Supervised_Lenders_License_2020.pdf\" target=\"_blank\">Haga clic para ver la licencia de Carolina del Sur SLW1-1916692 (PDF)</a></br><a href=\"South_Carolina_Consumer_Pamphlet.pdf\" target=\"_blank\">Haga clic para ver el folleto del consumidor de Carolina del Sur (PDF)</a>",
  "footer.NVDisclaimerTitle": "Aviso para residentes de Nevada",
  "footer.NVDisclaimer": `
    Agencia de cobro de Spring Oaks Capital, LLC en Nevada Número de licencia: CAD11626<br/>
    Los gerentes de cumplimiento de Spring Oaks Capital, LLC en Nevada son Jason Collins, número: CM12572, y Timothy Rees, número: CM12697`,
  "footer.title": "¿Tiene alguna pregunta sobre su cuenta o Spring Oaks Capital?",
  "footer.notice.title": "Aviso para residentes de la ciudad de Nueva York",
  "footer.notice.desc": `También puede comunicarse con nosotros llamando a Tim Rees al 866-539-7554.  Número(s) de licencia del Departamento de Asuntos del Consumidor de la Ciudad de Nueva York: 2117984-DCWP "Para brindarle un mejor servicio, infórmenos si tiene alguna preferencia de idioma que no sea el inglés.  En este momento, nuestros servicios de acceso lingüístico se limitan a nuestra capacidad de poner a su disposición representantes de habla hispana cuando así lo solicite y proporcionarle nuestras comunicaciones por escrito en español. Además, hay disponible una traducción y una descripción de los términos de cobro de deudas de uso común en varios idiomas en el sitio web del Departamento de Asuntos del Consumidor de la Ciudad de Nueva York en <b><a href="http://www.nyc.gov/dca" target="_blank">www.nyc.gov/dca.</a></b>"`,
  "footer.notice.nmls": "<b>NMLS #1916692</b>",
  "footer.button": "Contáctenos",
  "menu.home": "Inicio",
  "menu.whoWeAre": "Quiénes somos",
  "menu.faq": "Preguntas frecuentes",
  "menu.careers": "Empleos",
  "menu.privacy": "Política de privacidad",
  "menu.getInTouch": "Contáctenos",
  "WeAreHereSection.title": "Estamos aquí para ayuda",
  "WeAreHereSection.desc": "Ofrecemos <b>opciones flexibles de autoservicio</b> para resolver su deuda.<br/>Si necesita ayuda, nuestros experimentados y amables empleados están disponibles por teléfono o correo electrónico para ayudarle.",
  "WeAreHereSection.Illus": "Hombre rodeado de dispositivos y símbolos tecnológicos",
  "quoteSection.title": "Lo que dicen los consumidores",
  "quoteSection_quotes": [
    {
      "name": "S.C. (Connecticut)",
      "quote": "“¡Ronald fue maravilloso! Sé que en las agencias de cobro tienen mala fama, pero si su empresa es algún ejemplo de cómo me trataron hoy, están haciendo un gran trabajo”.",
      "src": "member-quotes/avatar-ronald.jpeg",
      "employee": "Ronald"
    },
    {
      "name": "J.S. (Ohio)",
      "quote": "“Tia es muy buena en su trabajo. Nadie quiere hablar con un cobrador de deudas, pero Tia me hizo querer hablar. Fue comprensiva, cortés y respetuosa”.",
      "src": "member-quotes/avatar-tia.jpeg",
      "employee": "Tia"
    },
    {
      "name": "M.B. (South Carolina)",
      "quote": "“¡Tienen una empleada maravillosa! Charles tenía una actitud maravillosa y facilitó la tarea de atar cabos sueltos. No me dejó colgarle el teléfono y me aseguró que podríamos encontrar una solución y me alegra que lo hizo. Con un bebé en camino, ahora tengo una cosa menos de la que preocuparme”.",
      "src": "member-quotes/avatar-charles.jpeg",
      "employee": "Charles"
    }
  ],
  "privacy.title": "Política de privacidad general",
  "privacy.ca.title": "Política de privacidad aplicable a los residentes de California",
  "privacy.button": "Descargar el archivo PDF",
  "privacy.tab-one": "Política de privacidad 1",
  "privacy.tab-two": "Política de privacidad 2",
  "privacy.tabs": [
    {
      "tab": "Política de privacidad general",
      "link": "/privacy-policy"
    },
    {
      "tab": "Política de privacidad para residentes de California",
      "link": "/privacy-policy-ca"
    }
  ],
  "privacy.content": [
    {
      "heading": "Propósito",
      "body": "Spring Oaks Capital, LLC y sus filiales, incluida Spring Oaks Capital SPV, LLC (en conjunto “Spring Oaks”, “nosotros” o “nos”) se comprometen a proteger la privacidad y seguridad de la información que recibe para garantizar la seguridad de nuestros activos por parte de todos los empleados de Spring Oaks, (en conjunto, “Usuarios”) y proteger la información privada con respecto a sus consumidores como prioridad principal. De conformidad con los requisitos de la Ley Gramm-Leach-Bliley (la “GLBA”) y las pautas establecidas por la Comisión de Bolsa y Valores (Securities and Exchange Commission, SEC) en relación con la Privacidad de la información financiera del consumidor (Reglamentación P), esta política (la “Política” o la “Política sobre la Reglamentación P”) y los procedimientos relacionados contenidos en el presente documento están diseñados para cumplir con las leyes de privacidad aplicables, incluidas la GLBA y la Reglamentación P, y para proteger la información personal no pública de los consumidores. En el caso de que las nuevas leyes o reglamentaciones relacionadas con la privacidad que afecten las prácticas de información de Spring Oaks sean adoptadas por reguladores federales o estatales, esta Política se revisará según sea necesario y cualquier cambio será difundido y explicado a todo el personal."
    },
    {
      "heading": "Alcance",
      "body": "Esta Política cubre las prácticas de Spring Oaks y se aplica a toda la información de identificación personal no pública, incluida la información contenida en los informes del consumidor, de nuestros clientes actuales y anteriores y de los consumidores con los que interactuamos. Todas las empresas financieras deben compartir la información personal para llevar a cabo sus actividades comerciales diarias. Esta Política enumera los motivos por los que las empresas financieras pueden compartir información personal, los motivos por los que Spring Oaks decide compartirla y si usted puede limitar esta compartición."
    },
    {
      "heading": "Política",
      "body": "Spring Oaks y sus empleados reconocen un compromiso continuo con la privacidad de sus consumidores. Se espera que todos los empleados lean, comprendan y respeten esta política, así como que sigan todos los procedimientos relacionados y respeten los estándares de privacidad y seguridad establecidos por Spring Oaks."
    },
    {
      "heading": "Procedimientos",
      "subsections": [
        {
          "description": "En la Reglamentación P, la SEC publicó pautas, de conformidad con la sección 501(b) de la GLBA, que abordan las medidas que debe tomar una institución financiera para proteger la información del consumidor. Los estándares de seguridad generales que deben cumplirse son:",
          "items": [
            "Garantizar la seguridad y confidencialidad de los registros e información del consumidor;",
            "Proteger contra cualquier amenaza o peligro anticipados para la seguridad o integridad de los registros e información del consumidor; y",
            "Proteger contra el acceso no autorizado o el uso de registros o información del consumidor que podrían resultar en daños o inconvenientes sustanciales para cualquier consumidor."
          ]
        },
        {
          "heading": "Responsabilidad",
          "items": [
            "Cada Empleado tiene el deber de proteger la información personal no pública de los consumidores que recopilamos.",
            "Cada Empleado tiene el deber de garantizar que la información personal no pública de los consumidores se comparta solo con los empleados y otros de una manera que sea coherente con el Aviso de privacidad de Spring Oaks y los procedimientos contenidos en esta Política.",
            "Cada Empleado tiene el deber de garantizar que el acceso a la información personal no pública de los consumidores sea limitado según lo dispuesto en el Aviso de privacidad y esta Política.",
            "Ningún empleado está autorizado a vender, en nombre de Spring Oaks o de otro modo, información no pública de los consumidores.",
            "Los Empleados que tengan preguntas sobre la recopilación y compartición de, o el acceso a, información personal no pública de los consumidores deben recurrir al director comercial (chief commercial officer, CCO) de Spring Oaks para obtener orientación.",
            "Las infracciones de estas políticas y procedimientos se abordarán de forma coherente con otras pautas disciplinarias."
          ]
        },
        {
          "heading": "Prácticas de información",
          "description": "Spring Oaks limita el uso, la recopilación y la conservación de información del consumidor a lo que creemos necesario o útil para llevar a cabo nuestro negocio u ofrecer productos y servicios de calidad. Spring Oaks recopila información personal no pública sobre los consumidores de diversas fuentes. Estas fuentes y ejemplos de tipos de información recopilada incluyen:",
          "items": [
            "Nombre, dirección, número de teléfono, número de Seguro Social o número de identificación fiscal, fecha de nacimiento, situación laboral, ingresos anuales y patrimonio neto.",
            "Información sobre transacciones con nosotros y el(los) custodio(s) de la cuenta, como el saldo de la cuenta, tipos de transacciones, partes de las transacciones.",
            "Información recibida de agencias de informes de crédito del consumidor, como informes de crédito, propietarios anteriores de la cuenta, agencias gubernamentales y otras compañías."
          ],
          "table": [
            [
              "Motivos por los que podemos compartir su información personal",
              "¿Comparte Spring Oaks?",
              "¿Puede limitar esta compartición?"
            ],
            [
              "Para nuestros fines comerciales cotidianos: como procesar sus transacciones, mantener su(s) cuenta(s), responder a órdenes judiciales e investigaciones legales o informar a agencias de información crediticia",
              "Sí",
              "No"
            ],
            [
              "Para nuestros fines de marketing: ofrecerle nuestros productos y servicios",
              "No",
              "No"
            ],
            [
              "Para el marketing conjunto con otras empresas financieras",
              "No",
              "No compartimos"
            ],
            [
              "Para los fines comerciales cotidianos de nuestras filiales: como información sobre sus transacciones y experiencias",
              "Sí",
              "No"
            ],
            [
              "Para los fines comerciales cotidianos de nuestras filiales: como información sobre su solvencia",
              "Sí",
              "No"
            ],
            [
              "Para que las empresas no filiales le comercialicen",
              "No",
              "No compartimos"
            ]
          ],
          "footer": [
            "Definiciones: Las filiales son empresas relacionadas por propiedad o control común. Pueden ser empresas financieras y no financieras.",
            "Las empresas no filiales son empresas no relacionadas por propiedad o control común. Pueden ser empresas financieras y no financieras.",
            "El marketing conjunto es un acuerdo formal entre empresas financieras no filiales que juntas le comercializan productos o servicios financieros. Spring Oaks no comercializa conjuntamente."
          ]
        },
        {
          "heading": "Divulgación de información a terceros no afiliados",
          "description": "Spring Oaks no divulga información personal no pública a terceros no afiliados, excepto bajo una de las excepciones de privacidad de la GLBA, como se describe a continuación.",
          "items": [
            <>
              Tipos de divulgaciones permitidas: las excepciones
              <ol>
                <li>
                  En determinadas circunstancias, la Reglamentación P permite a Spring Oaks compartir información personal no pública sobre sus consumidores con terceros no afiliados sin proporcionar una oportunidad para que esas personas se excluyan. Estas circunstancias incluyen compartir información con una entidad no afiliada:
                  <ol>
                    <li>Según sea necesario para efectuar, administrar o hacer cumplir una transacción que un cliente solicita o autoriza;</li>
                    <li>En relación con el procesamiento o la administración de una cuenta de cobro o un servicio que un cliente autoriza; y</li>
                    <li>En relación con el mantenimiento o la administración de una cuenta de cobro con Spring Oaks.</li>
                  </ol>
                </li>
              </ol>
            </>
          ]
        },
        {
          "heading": "Proveedores de servicios",
          "items": [
            "Ocasionalmente, podemos tener relaciones con terceros no afiliados (como abogados, auditores, custodios y otros consultores), que, en el curso ordinario de la prestación de sus servicios, pueden requerir acceso a información que contiene información no pública. Estos proveedores de servicios externos son necesarios para que podamos proporcionar nuestros servicios de cobro.",
            "Cuando no estamos seguros de que los proveedores de servicios (p. ej., abogados, auditores y otras instituciones financieras) ya estén obligados por obligaciones de confidencialidad, exigimos garantías de esos proveedores de servicios de que mantendrán la confidencialidad de la información no pública que obtengan de nosotros o a través de nosotros.",
            "Además, seleccionamos y contratamos proveedores de servicios que creemos que son capaces de mantener las salvaguardas adecuadas para la información no pública, y exigiremos acuerdos contractuales de nuestros proveedores de servicios que establecen que implementarán y mantendrán dichas salvaguardas."
          ]
        },
        {
          "heading": "Procesamiento y administración de transacciones",
          "items": [
            <>
              También podemos compartir información cuando sea necesario para efectuar, administrar o hacer cumplir una transacción solicitada o autorizada por nuestros consumidores.
              <ol><li>En este contexto, “necesario para efectuar, administrar o hacer cumplir una transacción”: incluye lo que se requiere o es un método habitual, apropiado o aceptable para llevar a cabo la transacción o el servicio del que forma parte la transacción, y registrar, administrar o mantener la cuenta del consumidor en el curso ordinario de la prestación de servicios de cobro.</li></ol>
            </>
          ]
        },
        {
          "heading": "Compartición según lo permita o exija la ley",
          "items": [
            <>
              Spring Oaks puede divulgar información a terceros no afiliados según lo requiera o permita la ley.
              <ol><li>Por ejemplo, esto puede incluir divulgaciones en relación con una citación o un proceso legal similar, una investigación de fraude, una auditoría o un examen.</li></ol>
            </>,
            "Al comprender cómo compartimos los datos con nuestros clientes, sus agentes, proveedores de servicios, partes relacionadas en transacciones en el curso ordinario de los negocios o comercializadores conjuntos, nos esforzamos por garantizar que los datos del cliente se compartan solo conforme a las excepciones mencionadas anteriormente."
          ]
        },
        {
          "heading": "Aviso de privacidad",
          "items": [
            "Spring Oaks enviará un Aviso de privacidad en virtud de la Reglamentación P sobre las cuentas que posee de conformidad con esta Política."
          ]
        }
      ]
    },
    {
      "heading": "Procedimientos de auditoría",
      "body": "Spring Oaks monitoreará periódicamente los sistemas electrónicos como el correo electrónico y el Internet. Cualquier infracción directa, indirecta o intento de infracción, de esta Política, por parte de o en nombre de un Usuario, y cualquier infracción real o intento de infracción por parte de un Tercero en nombre de un Usuario, se considerará una infracción de la Política por parte del Usuario, y el Usuario será considerado directamente responsable. En el caso de que tengamos conocimiento de que cualquier actividad del Usuario puede haber infringido esta Política y/o nos ha expuesto a responsabilidad civil o penal, Spring Oaks se reserva el derecho de investigar dicha actividad; monitorear, recopilar pruebas y bloquear el acceso a dicho material; y cooperar con las autoridades legales y terceros en la investigación de cualquier presunta infracción de esta Política. También nos reservamos el derecho de implementar mecanismos técnicos para evitar infracciones de políticas, incluido el monitoreo electrónico de sistemas como el correo electrónico y el Internet. Los usuarios que infrinjan esta Política o cualquier otra política o norma publicada por Spring Oaks podrían estar sujetos a medidas disciplinarias por parte de nosotros, que pueden incluir el despido inmediato. Además, las conductas que sean ilegales en virtud de las leyes aplicables pueden someter a los Usuarios al enjuiciamiento civil y, en algunos casos, penal."
    },
    {
      "heading": "Enlaces a otros sitios web",
      "body": "Nuestro sitio web puede contener enlaces para permitirle visitar otros sitios web de interés fácilmente. Sin embargo, una vez que haya utilizado estos enlaces para salir de nuestro sitio, debe tener en cuenta que no tenemos ningún control sobre ese otro sitio web. Por lo tanto, no podemos ser responsables de la protección y privacidad de cualquier información que proporcione mientras visita dichos sitios y dichos sitios no se rigen por esta declaración de privacidad. Debe tener cuidado y consultar la declaración de privacidad aplicable al sitio web en cuestión."
    },
    {
      "heading": "Cómo protegemos la información",
      "body": [
        "Spring Oaks ha implementado medidas de seguridad físicas, electrónicas y procedimentales para proteger contra la divulgación o el acceso no autorizados a la información personal. Empleamos salvaguardas de sistemas internos y externos diseñadas para proteger la confidencialidad y seguridad de la información personal. La confidencialidad de cualquier comunicación o material transmitido hacia o desde Spring Oaks a través del sitio web o por correo electrónico no puede garantizarse ni está garantizada. Usted reconoce que el procesamiento técnico y la transmisión del contenido del sitio web pueden transferirse sin cifrar e implican: (a) transmisiones a través de varias redes; y (b) cambios para confirmar y adaptarse a los requisitos técnicos de las redes o los dispositivos de conexión.",
        "Si surge alguna pregunta sobre la seguridad, comuníquese con nosotros utilizando la información proporcionada anteriormente."
      ]
    },
    {
      "heading": "Frecuencia de capacitación y pruebas",
      "body": [
        "Todo el personal de Spring Oaks recibirá capacitación sobre las políticas de nuestra empresa aplicables a su puesto tras la contratación o el ascenso. El mismo personal completará certificaciones y evaluaciones sobre esas políticas una vez finalizada la capacitación y anualmente.",
        "Las preguntas o los comentarios sobre nuestras prácticas de datos pueden enviarse a:",
        "Spring Oaks Capital, LLC\nP.O. Box 1216\nChesapeake, VA 23327-1216",
        "O bien, llamándonos al número gratuito 866-281-3065."
      ]
    }
  ],
  "error.title": "No se encontró la página",
  "error.desc": "La página que estaba buscando no existe",
  "error.page.altimage": "Mujer que sostiene el logotipo de Spring Oaks Capital",
  "error.page.button": "Volver a la página de inicio",
  "contactUs.title": "¿Tiene una pregunta?",
  "contactUs.name": "Nombre",
  "contactUs.email": "Correo electrónico",
  "contactUs.reference": "Número de referencia",
  "contactUs.phone": "Número de teléfono",
  "contactUs.query": "¿Cómo podemos ayudar?",
  "contactUs.submit": "Enviar",
  "contactUs.disclaimer": "Si tiene alguna inquietud con respecto a su cuenta, comuníquese con nosotros",
  "contactUs.disclaimerDetails": "Spring Oaks Capital, LLC<br />P.O. Box 1216<br />Chesapeake, VA 23327-1216<br />(866) 281-3065<br />Email: <a href=\"mailto:customerservice@springoakscapital.com\">customerservice@springoakscapital.com</a>",
  "contactUs.disclaimerDetails2": "Catherine Calko, Esq.<br />Directora de Cumplimiento<br />Número de certificación RMAi de la empresa: C1911-1132<br />Número de certificación RMAi individual: P2112-1448<br />(833) 398-0024<br />Educación del consumidor: <a>rmaintl.org/consumers</a>",
  "contactUs.altimage": "Dos personas reunidas con un teléfono",
  "contactUs.success.title": "Gracias por contactarnos",
  "contactUs.success.desc": "Responderemos su consulta en breve.",
  "contactUs.success.button": "Volver a la página de inicio",
  "contactUs.errorMessage": "¡Vaya! Se produjo un error. Asegúrese de que todos los campos tengan el contenido correspondiente",
  "contactUs.tooltip.title": "¿Cuál es el número de referencia?",
  "contactUs.tooltip.desc": "El número de referencia es el número único que utilizamos para identificar su cuenta. Puede encontrar su número de referencia en la carta que le enviamos por correo postal y en cada correo electrónico que le enviamos.",
  "about.header.title": "Quiénes somos",
  "about.header.desc": "Tenemos la <b>misión de ayudar a los consumidores</b> a reducir la carga de sus obligaciones financieras",
  "about.card.title": "La empresa se fundó porque creemos que hay una mejor manera de ayudar a los consumidores a lidiar con su deuda",
  "about.card.desc": "En Spring Oaks Capital, creemos que <b>el cobro de deudas no tiene por qué ser una experiencia estresante</b> o negativa. Nos centramos en crear <b>excelentes resultados</b> para todos los consumidores.",
  "about.card.button": "Contáctenos",
  "about.careers.title": "Empleos vacantes",
  "about.careers": [
    {
      "title": "Asociado de cobros",
      "city": "Chesapeake, VA",
      "url": "/"
    }
  ],
  "about.team.title": "Nuestro equipo",
  "about.partners.title": "Nuestros socios y acreditaciones del sector",
  "faqSection.title": "Preguntas frecuentes",
  "faqSection.questions": [
    {
      "title": "¿Cómo accedo a los detalles de mi cuenta?",
      "answer": "Ofrecemos la posibilidad de acceder a su cuenta a través de nuestro portal de autoservicio. Puede acceder a su cuenta haciendo clic en el botón Ver mi cuenta en la parte superior de la página."
    },
    {
      "title": "¿Por qué se comunican conmigo?",
      "answer": "Su cuenta se originó con otra institución financiera, pero actualmente es propiedad de Spring Oaks Capital SPV, LLC. Nos gustaría ayudarle a encontrar una resolución adecuada para usted."
    },
    {
      "title": "¿Cómo puedo solicitar los documentos originales de mi préstamo?",
      "answer": "Puede solicitar los documentos originales del préstamo llamándonos al (866)-281-3065 o a través de la página Contáctenos."
    }
  ],
  "team.quotes": [
    {
      "quote": "Disfruto de poder ayudar a las personas que están pasando por tiempos difíciles. He estado allí yo misma, así que es muy satisfactorio cuando puedo ayudarlas en su momento de necesidad",
      "name": "Melissa",
      "src": "team-members/avatar-melissa.jpeg"
    },
    {
      "quote": "Disfruto apoyando la misión de ayudar a cada consumidor a acercarse a la libertad financiera. Para lograrlo, ofrezco un excelente servicio al cliente en cada llamada y demuestro disposición para trabajar con ellos independientemente de su situación.",
      "name": "Arshanika",
      "src": "team-members/avatar-arshanika.jpeg"
    },
    {
      "quote": "Trabajar para ayudar a nuestros clientes a liberarse de deudas ha sido increíble. Disfruto de poder ayudar a nuestros clientes a aliviar el estrés financiero proporcionando la mejor asistencia al cliente y modelando los valores fundamentales de nuestra empresa",
      "name": "Alaysia",
      "src": "team-members/avatar-alaysia.jpeg"
    },
    {
      "quote": "Trabajar para ayudar a nuestros clientes a liberarse de deudas ha sido increíble. Disfruto de poder ayudar a nuestros clientes a aliviar el estrés financiero proporcionando la mejor asistencia al cliente y modelando los valores fundamentales de nuestra empresa",
      "name": "Will",
      "src": "team-members/avatar-will.jpeg"
    },
    {
      "quote": "Trabajar para ayudar a nuestros clientes a liberarse de deudas ha sido increíble. Disfruto de poder ayudar a nuestros clientes a aliviar el estrés financiero proporcionando la mejor asistencia al cliente y modelando los valores fundamentales de nuestra empresa",
      "name": "Shameeka",
      "src": "team-members/avatar-shameeka.jpeg"
    }
  ]
}
